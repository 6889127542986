<template>
    <div class="top-bar button-line">
        <div class="top-bar-search">
            <input type="text" class="search-input" :placeholder="$t('Search...')" />
            <div class="search-icon"><i class="fa-solid fa-magnifying-glass"></i></div>
        </div>

        <div class="top-bar-user-td" v-if="loggedIn">
            <button type="button" class="top-bar-button notification-button has-notifications" :title="$t('Notifications')">
                <i class="fa-solid fa-bell"></i>
            </button>

            <button type="button" class="top-bar-button settings-button" :title="$t('Settings')">
                <i class="fa-solid fa-gear"></i>
            </button>

            <div class="top-bar-button-separator"></div>

            <span class="top-bar-user-message"
                >{{ $t("Hello") }}, <b>{{ renderName(profileName) }}</b></span
            >
            <button type="button" class="top-bar-button top-bar-user-settings-button" :title="$t('User settings')" @click="openUserSettings">
                <img class="logo" src="/svg/avatar.svg" />
            </button>
        </div>

        <div class="top-bar-user-td" v-else>
            <button type="button" class="top-bar-button login-button" :title="$t('Login')" @click="login">
                <i class="fa-solid fa-sign-in"></i>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { ColorThemeName, getTheme, setTheme } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    name: "TopBar",
    emits: ["toggle-menu", "openModal"],
    data: function () {
        return {
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            theme: getTheme(),
            profileName: AuthController.ProfileName || AuthController.Username || "Diego",
        };
    },
    methods: {
        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        invertTheme: function () {
            setTheme(this.theme === "dark" ? "light" : "dark");
        },

        login: function () {
            this.$requireLogin();
        },

        clickMenu: function () {
            this.$emit("toggle-menu");
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
    },
    beforeUnmount: function () {},
});
</script>

<style></style>
